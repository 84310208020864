
import { mapActions, mapState } from 'vuex'
import roleTypes from '../constants/roleTypes'

export default {
  name: 'NowNav',
  data () {
    return {
      roleTypes
    }
  },
  computed: {
    ...mapState({
      sidebarToggled: state => state.app.sidebarToggled
    }),
    isValid () {
      return !!this.form.query
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'app/toggleSidebar',
      getCompanyUnitIndex: 'companyUnit/getCompanyUnitIndex'
    }),
    initialize () {
      this.getCompanyUnitIndex()
    },
    handleSidebarToggle () {
      this.toggleSidebar(!this.sidebarToggled)
    }
  }
}
