import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _030eb415 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5eafad24 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _638c946a = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _fea538fa = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _b64993ae = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _4a3b214e = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _60151d91 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _574dc0b5 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6c79fbc8 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _0b6da949 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _7fb98cbe = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _5028f091 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _9dbb42bc = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _3c30273d = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _97c523d4 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _7e6cd80d = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _99cba41c = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _e858f31a = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _797277b2 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _1f9200b2 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _05df5a3a = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _45db9bb0 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _291bd766 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _22144cea = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _389c450a = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _2a8fd2aa = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _56132eda = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _67d85215 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _066abe68 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _f2b326b0 = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _def83bc4 = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _358dd70f = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _7268a57f = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _29b4de89 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _63268423 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _772c1a51 = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _6de5960f = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _535304ee = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _316b1494 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _31d235c4 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _06f14ee6 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _d7320046 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _7e04699d = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _3ef50197 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _82a615da = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _2e6ad677 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _030eb415,
    name: "account"
  }, {
    path: "/action",
    component: _5eafad24,
    name: "action"
  }, {
    path: "/archive",
    component: _638c946a,
    name: "archive"
  }, {
    path: "/audit",
    component: _fea538fa,
    name: "audit"
  }, {
    path: "/deviation",
    component: _b64993ae,
    name: "deviation"
  }, {
    path: "/it",
    component: _4a3b214e,
    name: "it"
  }, {
    path: "/login",
    component: _60151d91,
    name: "login"
  }, {
    path: "/logout",
    component: _574dc0b5,
    name: "logout"
  }, {
    path: "/offline",
    component: _6c79fbc8,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _0b6da949,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _7fb98cbe,
    name: "organization"
  }, {
    path: "/register",
    component: _5028f091,
    name: "register"
  }, {
    path: "/report",
    component: _9dbb42bc,
    name: "report"
  }, {
    path: "/risk",
    component: _3c30273d,
    name: "risk"
  }, {
    path: "/search",
    component: _97c523d4,
    name: "search"
  }, {
    path: "/styles",
    component: _7e6cd80d,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _99cba41c,
    name: "suggestion"
  }, {
    path: "/task",
    component: _e858f31a,
    name: "task"
  }, {
    path: "/unit",
    component: _797277b2,
    name: "unit"
  }, {
    path: "/verify",
    component: _1f9200b2,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _05df5a3a,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _45db9bb0,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _291bd766,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _22144cea,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _389c450a,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _2a8fd2aa,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _56132eda,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _67d85215,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _066abe68,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _f2b326b0,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _def83bc4,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _358dd70f,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _7268a57f,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _29b4de89,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _63268423,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _772c1a51,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _6de5960f,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _535304ee,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _316b1494,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _31d235c4,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _06f14ee6,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _d7320046,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _7e04699d,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _3ef50197,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _82a615da,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _2e6ad677,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
