import Vue from 'vue'

Vue.filter('currency', function (amount, decimal = 0, locale = 'da') {
  // Convert to float
  const floatAmount = parseFloat(amount)

  // Check if valid value or return null
  if (isNaN(floatAmount) || !isFinite(floatAmount)) {
    return null
  }

  // Format the amount with commas for thousands separator
  return floatAmount.toLocaleString(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal
  })
})
